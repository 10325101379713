<template>
  <div>
    <div class="navigation_results">
      <div class="container-fluid">
        <div class="wrapper">
          <div class="row justify-content-center">
            <div
              class="
                col-sm-3
                p-1
                d-flex
                justify-content-center
                align-items-center
                my-1
              "
            >
              <div class="result_blog">
                <span class="true">{{ display_right_answer }}</span>
                <span>/</span>
                <span class="wrong">{{ display_answer }}</span>
                <span class="fas fa-clock ml-4"> : {{ display.time }} </span>
              </div>
            </div>
            <div
              class="
                col-sm-3
                p-1
                d-flex
                justify-content-center
                align-items-center
                my-1
              "
            >
              <div class="nav">
                <ul class="main_menu">
                  <li class="main_menu_li">
                    <router-link :to="{ name: 'home' }">
                      <i class="fas fa-home"></i>
                    </router-link>
                  </li>
                  <li class="main_menu_li">
                    <router-link :to="{ name: 'display_multiply_settings' }">
                      <i class="fas fa-cogs"></i>
                    </router-link>
                  </li>
                  <li class="main_menu_li">
                    <a href="#" @click.prevent="newPlay">
                      <i class="fas fa-play"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main" style="margin-top: 10px">
      <div v-if="game.isPlaying && !game.isChecked" class="container-fluid">
        <div class="wrapper">
          <div class="answers">
            <span class="wrong_answer"> {{ display.lastNumbers }} </span>
          </div>
          <div class="multiply_numbers">
            <transition mode="out-in" name="fade">
              <span
                :key="display.array_index"
                :class="{ 'text-success': game.isIntro }"
                class="multiply_number"
              >
                {{ display.current | son }}
              </span>
            </transition>
          </div>
          <input
            id="answer"
            ref="answer"
            v-model="game.user_answer"
            class="answer_input"
            type="number"
            @keyup.enter="check"
          />
        </div>
      </div>
      <div v-if="game.isChecked" class="container-fluid">
        <div class="wrapper">
          <div class="multiply_numbers">
            <span class="multiply_number text-success">
              {{ display.summary_text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "start",
  data: function () {
    return {
      game: {
        isIntro: true,
        isPlaying: false,
        isChecked: false,
        user_answer: "",
      },
      vars: {
        audio: {
          success: "",
          fail: "",
        },
        finished: false,
        music: "",
        interval: "",
      },
      display: {
        lastNumbers: "",
        current: "",
        start_index: 0,
        array_index: "-",
        summary_text: "",
        time: 0,
      },
    };
  },
  computed: {
    state() {
      return this.$store.getters["multiply/state"];
    },
    games() {
      return this.$store.getters["game/game"];
    },
    answer() {
      return (
        this.state.numbers[this.display.array_index][0] *
        this.state.numbers[this.display.array_index][1]
      );
    },
    display_answer() {
      return this.state.complete.number_row;
    },
    display_right_answer() {
      return this.state.complete.answer;
    },
    display_time() {
      return this.state.settings.number_speed;
    },
  },
  methods: {
    startBeginning() {
      this.game.isIntro = true;
      this.display.time = this.display_time;
      this.game.isPlaying = true;
      this.vars.interval = setInterval(
        this.doBeginning,
        this.games.start.time * 1000
      );
    },
    doBeginning() {
      if (
        typeof this.games.start.texts[this.display.start_index] === "undefined"
      ) {
        clearInterval(this.vars.interval);
        this.display.start_index = 0;
        this.startGaming();
      } else {
        this.display.current = this.games.start.texts[this.display.start_index];
        this.display.start_index++;
      }
    },
    async startGaming() {
      this.game.isIntro = false;
      this.display.array_index = 0;
      await this.$store.commit("multiply/INC_NUMBER_ROW");
      this.vars.interval = setInterval(() => {
        if (this.display.time > 1) {
          this.display.time--;
        } else {
          this.display.time--;
          clearInterval(this.vars.interval);
          this.vars.finished = true;
          this.display.summary_text =
            this.display_right_answer +
            " / " +
            this.display_answer +
            ". " +
            this.state.settings.number_speed +
            " sekund";
          this.game.isChecked = true;
          this.$store.dispatch("multiply/complete");
        }
      }, 1000);
      await this.next();
    },
    async next() {
      if (typeof this.state.numbers[this.display.array_index] === "undefined") {
        this.game.isPlaying = false;
      } else {
        if (this.state.numbers.length - this.display.array_index === 10) {
          await this.$store.dispatch("multiply/add_new_numbers");
        }
        this.playAudio(this.games.audios.alert);
        this.display.current =
          this.state.numbers[this.display.array_index][0] +
          " x " +
          this.state.numbers[this.display.array_index][1];
        await this.focus();
      }
    },
    async newPlay() {
      this.vars.finished = false;
      this.game.user_answer = "";
      clearInterval(this.vars.interval);
      this.display.current = "";
      this.game.isPlaying = true;
      this.game.isChecked = false;
      this.$store.commit("multiply/CLEAR_COMPLETE");
      if (await this.$store.dispatch("multiply/prepare", this.state.settings)) {
        this.startBeginning();
      }
    },
    focus() {
      this.$refs.answer.focus();
    },
    async check() {
      if (!this.vars.finished) {
        await this.$store.commit("multiply/INC_NUMBER_ROW");
        if (parseInt(this.game.user_answer) === parseInt(this.answer)) {
          this.display.lastNumbers = "";
          await this.$store.commit("multiply/INC_ANSWER");
        } else {
          this.display.lastNumbers =
            this.state.numbers[this.display.array_index][0] +
            " * " +
            this.state.numbers[this.display.array_index][1] +
            " = " +
            this.answer +
            " ≠" +
            this.game.user_answer;
        }
        this.game.user_answer = "";
        this.display.array_index++;
        await this.next();
      }
    },
    playAudio(src) {
      this.vars.music = new Audio(src);
      this.vars.music.play();
    },
  },
  mounted() {
    if (this.state.isReady) {
      this.vars.music = new Audio(this.games.audios.alert);
      this.vars.audio.success = new Audio(this.games.audios.success);
      this.vars.audio.fail = new Audio(this.games.audios.fail);
      this.startBeginning();
    } else {
      this.$router.push({ name: "display_multiply_settings" });
    }
  },
  beforeDestroy() {
    clearInterval(this.vars.interval);
    this.$store.commit("multiply/CLEAR_DATA");
  },
};
</script>
